// import axios from "axios";
import parse from "csv-parse/lib/sync";
import axios from "axios";
import { message } from "antd";
import { normaliseRow } from "../../services/dataConversionService";
import {
  LANDLORD_TENANTS_ACT,
  SPECIFICATION,
  BUSINESS_TYPE,
  RESTRICTED,
} from "@constants/rentalRecord.json";
import { adminFetchRentalRecords } from "@store/admin/rentalRecords";

message.config({
  top: 100,
});

// set max number of records to upload to the server in a single request
const BATCH_SIZE = 100;

// actions
const INIT = "admin/dataSetUpload/INIT";
const SET_RAW_DATA = "admin/dataSetUpload/SET_RAW_DATA";
const PARSING_START = "admin/dataSetUpload/PARSING_START";
const PARSING_END = "admin/dataSetUpload/PARSING_END";
const PROCESSING_START = "admin/dataSetUpload/PROCESSING_START";
const PROCESSING_END = "admin/dataSetUpload/PROCESSING_END";
const SET_ERROR = "admin/dataSetUpload/SET_ERROR";
const CLEAR_ERROR = "admin/dataSetUpload/CLEAR_ERROR";
const ADD_RECORD = "admin/dataSetUpload/ADD_RECORD";
const ADD_PROCESSING_ERRORS = "admin/dataSetUpload/ADD_PROCESSING_ERRORS";
const UPLOAD_INIT = "admin/dataSetUpload/UPLOAD_INIT";
const UPLOAD_SUCCESS = "admin/dataSetUpload/UPLOAD_SUCCESS";
const UPLOAD_ERROR = "admin/dataSetUpload/UPLOAD_ERROR";
const SET_BATCH_COUNT = "admin/dataSetUpload/SET_BATCH_COUNT";
const SET_BATCH_PROGRESS = "admin/dataSetUpload/SET_BATCH_PROGRESS";

// reducer
export const initialState = {
  hasError: false,
  error: "",
  rawData: [],
  processedRecords: [],
  isParsing: false,
  processingErrors: [],
  isUploading: false,
  batchCount: 0,
  batchProgress: 0,
};

export default function modify(state = initialState, action = {}) {
  switch (action.type) {
    case INIT:
      return {
        ...initialState,
      };
    case SET_RAW_DATA:
      return {
        ...state,
        rawData: action.payload,
      };
    case PROCESSING_START:
      return {
        ...state,
        isProcessing: true,
      };
    case PROCESSING_END:
      return {
        ...state,
        isProcessing: false,
      };
    case SET_ERROR:
      return {
        ...state,
        hasError: true,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        hasError: false,
        error: "",
      };
    case ADD_RECORD:
      return {
        ...state,
        processedRecords: [...state.processedRecords, action.payload],
      };
    case ADD_PROCESSING_ERRORS:
      return {
        ...state,
        processingErrors: [...state.processingErrors, ...action.payload],
      };
    case UPLOAD_INIT:
      return {
        ...state,
        error: "",
        hasError: false,
        isUploading: true,
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        error: "",
        hasError: false,
        isUploading: false,
      };
    case UPLOAD_ERROR:
      return {
        ...state,
        error: action.payload,
        hasError: true,
        isUploading: false,
      };
    case SET_BATCH_COUNT:
      return {
        ...state,
        batchCount: action.payload,
      };
    case SET_BATCH_PROGRESS:
      return {
        ...state,
        batchProgress: action.payload,
      };
    default:
      return state;
  }
}

// action creators
export const initDataSetUpload = () => ({ type: INIT });
export const setRawData = records => ({ type: SET_RAW_DATA, payload: records });
export const parsingStart = () => ({ type: PARSING_START });
export const parsingEnd = () => ({ type: PARSING_END });
export const setError = msg => ({ type: SET_ERROR, payload: msg });
export const clearError = () => ({ type: CLEAR_ERROR });
const processingStart = () => ({ type: PROCESSING_START });
const processingEnd = () => ({ type: PROCESSING_END });
const addRecord = record => ({ type: ADD_RECORD, payload: record });
const addProcessingErrors = errs => ({
  type: ADD_PROCESSING_ERRORS,
  payload: errs,
});
const uploadStart = () => ({ type: UPLOAD_INIT });
const uploadSuccess = () => ({ type: UPLOAD_SUCCESS });
const uploadError = err => ({ type: UPLOAD_ERROR, payload: err });
const setBatchCount = n => ({ type: SET_BATCH_COUNT, payload: n });
const setBatchProgress = n => ({ type: SET_BATCH_PROGRESS, payload: n });

// thunks

export function dataSetProcessTextFile(fileText, callback) {
  return async dispatch => {
    dispatch(clearError());
    dispatch(parsingStart());
    try {
      const parsed = parse(fileText, { columns: true, skip_empty_lines: true });
      dispatch(setRawData(parsed));
      callback && callback();
    } catch (err) {
      dispatch(setError(err.message));
      message.error(
        "There was a problem processing this file, please check it is a valid CSV file and try again",
      );
    } finally {
      dispatch(parsingEnd());
    }
  };
}

export const schema = {
  address: {
    type: "string",
  },
  postcode: {
    type: "string",
    required: true,
  },
  leaseLength: {
    type: "string",
  },
  landlordTenantsAct: {
    type: "enum",
    options: Object.values(LANDLORD_TENANTS_ACT),
    required: true,
  },
  lastRentReview: {
    type: "date",
    required: true,
  },
  nextRentReview: {
    type: "date",
  },
  landlordName: {
    type: "string",
  },
  specification: {
    type: "enum",
    options: Object.values(SPECIFICATION),
    required: true,
  },
  annualRent: {
    type: "number",
    required: true,
  },
  squareFeet: {
    type: "number",
    required: true,
  },
  priceSqFt: {
    type: "number",
    required: true,
  },
  yardSquareFeet: {
    type: "number",
  },
  yardPriceSqFt: {
    type: "number",
  },
  additionalZones: {
    type: "string",
  },
  serviceCharge: {
    type: "number",
  },
  businessType: {
    type: "enum",
    options: Object.values(BUSINESS_TYPE),
    required: true,
  },
  restricted: {
    type: "enum",
    options: Object.values(RESTRICTED),
    required: true,
  },
  breakClauses: {
    type: "string",
  },
  additionalComments: {
    type: "string",
  },
};

function validateRecord(record) {
  return async dispatch => {
    const normalised = normaliseRow(record, schema);
    if (normalised.isValid) {
      dispatch(addRecord(normalised.value));
    } else {
      dispatch(addProcessingErrors(normalised.errs));
    }
  };
}

export function dataSetValidateRecords() {
  return async (dispatch, getState) => {
    dispatch(processingStart());
    const state = getState();
    const rawData = state.admin.dataSetUpload.rawData;
    const promises = rawData.map(record => dispatch(validateRecord(record)));
    await Promise.all(promises);
    dispatch(processingEnd());
  };
}

function makeBatches(records) {
  if (records.length < BATCH_SIZE) return [records];
  return records.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / BATCH_SIZE);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
}

export function dataSetUploadRecords(id, callback) {
  return async (dispatch, getState) => {
    const state = getState();
    const user = state.user;
    if (!user.isAdmin && !user.isSuper) {
      return message.error("You do not have permission to upload this data");
    }
    dispatch(uploadStart());
    try {
      const records = state.admin.dataSetUpload.processedRecords;
      const batches = makeBatches(records);
      await dispatch(setBatchCount(batches.length));
      const processBatches = batches.map(async (batch, i) => {
        await axios.post(`/api/dataSets/${id}/rentalRecords`, batch);
        return dispatch(setBatchProgress(i + 1));
      });
      await Promise.all(processBatches);
      // await axios.post(`/api/dataSets/${id}/rentalRecords`, records);
      message.success("All data uploaded", 3, () => {
        dispatch(adminFetchRentalRecords());
        dispatch(uploadSuccess());
        callback && callback();
      });
    } catch (err) {
      message.error(
        (err && err.response && err.response.data && err.response.data.msg) ||
          "There was an error uploading your data set, please try again later.",
      );
      dispatch(uploadError(err.message));
      dispatch(adminFetchRentalRecords());
    }
  };
}
