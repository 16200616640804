import moment from "moment";

// conversion functions for the CSV upload. Each function should return false if the given value is
// invalid, or otherwise should return the converted, valid value. Expected input in each case is
// string, coming from the parsed CSV file

export const toNumber = val => {
  if (typeof val === "number") return { isValid: true, value: val };

  const digits = val.trim().replace(/[£,]/g, "");
  if (!digits) return { isValid: false, err: "no value provided" };

  const num = Number(digits);
  if (Number.isNaN(num))
    return { isValid: false, err: `value ${val} is not a valid number` };

  return { isValid: true, value: num };
};

export const oneOf = (val, set) => {
  if (!val) return { isValid: false, err: "no value provided" };
  return set.reduce(
    (a, c) => {
      if (c.toUpperCase() === val.trim().toUpperCase()) {
        a = { isValid: true, value: c };
      }
      return a;
    },
    { isValid: false, err: `value ${val} does not match the valid options` },
  );
};

export const isValidDateString = val => {
  val = val.replace(/[/]/g, "-");
  const date = moment(val, "DD-MM-YYYY", true);
  if (date.isValid() && date.isAfter("1900-01-01"))
    return { isValid: true, value: val };
  return { isValid: false, err: `value ${val} is not a valid DD-MM-YYYY date` };
};

// normaliser, takes an object and returns that object with keys normalised to ignore
// capitalisation

export const normaliseKeys = (obj, validKeys) => {
  const keys = Object.keys(obj);
  let keysValid = true;
  const normalisedObject = {};
  keys.map(key => {
    const normalisedKey = oneOf(key, validKeys);
    if (!normalisedKey.isValid) {
      keysValid = false;
    } else {
      normalisedObject[normalisedKey.value] = obj[key];
    }
  });
  if (keysValid) return { isValid: true, value: normalisedObject };
  return { isValid: false, err: "Could not match column names" };
};

export const normaliseRow = (row, schema) => {
  const validKeys = Object.keys(schema);
  const normalised = normaliseKeys(row, validKeys);

  let errs = [];

  if (!normalised.isValid) {
    errs.push(normalised.err);
    return { isValid: false, errs };
  }

  const output = validKeys.reduce((output, key) => {
    const originalValue = normalised.value[key];

    if (
      (originalValue === undefined || originalValue === "") &&
      schema[key].required
    ) {
      errs.push(`${key} is required`);
      return output;
    }

    if (schema[key].type === "number" && originalValue) {
      const result = toNumber(originalValue);
      if (result.isValid) {
        output[key] = result.value;
      } else {
        errs.push(result.err);
      }
    } else if (schema[key].type === "enum" && originalValue) {
      const result = oneOf(originalValue, schema[key].options);
      if (result.isValid) {
        output[key] = result.value;
      } else {
        errs.push(result.err);
      }
    } else if (schema[key].type === "date" && originalValue) {
      const result = isValidDateString(originalValue);
      if (result.isValid) {
        output[key] = result.value;
      } else {
        errs.push(result.err);
      }
    } else {
      output[key] = originalValue || undefined;
    }

    return output;
  }, {});

  if (errs.length > 0) return { isValid: false, errs };
  return { isValid: true, value: output };
};
