import axios from "axios";
import { message } from "antd";

message.config({
  top: 100,
});

// actions
const FETCH_INIT = "admin/rentalRecords/FETCH_INIT";
const FETCH_ERROR = "admin/rentalRecords/FETCH_ERROR";
const FETCH_SUCCESS = "admin/rentalRecords/FETCH_SUCCESS";
// reducer
export const initialState = {
  data: [],
  hasError: false,
  error: "",
  isLoading: false,
};

export default function rentalRecords(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_INIT:
      return { ...initialState, isLoading: true };
    case FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error:
          action.payload ||
          "There was an error retrieving rental records, please try again later.",
      };
    case FETCH_SUCCESS:
      return {
        ...initialState,
        data: action.payload || [],
      };
    default:
      return state;
  }
}

// action creators

// fetch all users
export function fetchRentalRecordsInit() {
  return { type: FETCH_INIT };
}

export function fetchRentalRecordsError(err) {
  return { type: FETCH_ERROR, payload: err };
}

export function fetchRentalRecordsSuccess(users) {
  return { type: FETCH_SUCCESS, payload: users };
}

export function adminFetchRentalRecords() {
  return async dispatch => {
    dispatch(fetchRentalRecordsInit());
    try {
      const response = await axios.get(`/api/rentalRecords`);
      dispatch(fetchRentalRecordsSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.msg;
      dispatch(fetchRentalRecordsError(errorMessage));
    }
  };
}
